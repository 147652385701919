.results-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  margin-top: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  white-space: nowrap;
  overflow-x: auto;
  text-overflow: ellipsis;
}

.results-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.results-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  white-space: nowrap;
  overflow-x: auto;
  text-overflow: ellipsis;
}

.results-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow-x: auto;
  text-overflow: ellipsis;
}

.results-item strong {
  display: block;
  margin-bottom: 5px;
  color: #555;
}