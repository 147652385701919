.dynamic-form {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
}

.toggle-advanced-options {
  background-color: #686868;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
}

.form-fields {
  margin-bottom: 20px;
  padding: 50 px;
}

.form-fields>div {
  margin-bottom: 15px;
  padding: 30 px;

}

.pathInput {
  margin: 15px;
  padding: 2px;
  width: 80%;
  align-items: start;
}

.delete-button {
  margin: 15px;
  padding: 2px;
  width: 80%;
  align-items: start;
}

.submit-button {
  background-color: #28a745;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}


.delete-button-container {
  position: relative;
  padding: 10px;
}

.delete-button {
  background-color: transparent;
  border: none;
  color: #888;
  font-size: 10px;
  cursor: pointer;
  padding: 5px;
  font-weight: lighter;
}


.add-button {
  background-color: transparent;
  border: none;
  color: #888;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  font-weight: lighter;
}

.delete-button:hover {
  color: #ff0000;
  /* Change the color on hover if desired */
}

.add-button:hover {
  color: #082e0d;
  /* Change the color on hover if desired */
}