.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('https://s3.fr-par.scw.cloud/static.tcp/Bellerose.ttf') format('truetype');
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}


/* Nouvelle règle CSS pour l'état de survol de l'icône à l'intérieur de la div .tooltip */
.action-icons .tooltip>span {
  color: rgb(175, 175, 175);
  opacity: 0.7;
  /* Opacité par défaut des icônes */
  transition: opacity 0.3s ease;
  /* Transition de l'opacité */
}

/* Nouvelle règle CSS pour l'état de survol de l'icône à l'intérieur de la div .tooltip */
.action-icons .tooltip>span:hover {
  color: rgb(175, 175, 175);
  opacity: 1;
}

.action-icons .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #858585;
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Positionnement de la bulle */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* La bulle apparaît au-dessus du texte */
  left: 50%;
  transform: translateX(-50%);
  /* Centrer la bulle par rapport au texte */

  /* Transition pour l'animation */
  transition: visibility 0.01s;
  opacity: 1;
  cursor: None;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #858585;
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Positionnement de la bulle */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* La bulle apparaît au-dessus du texte */
  left: 50%;
  transform: translateX(-50%);
  /* Centrer la bulle par rapport au texte */

  /* Transition pour l'animation */
  transition: visibility 0.01s;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  /* La flèche est au-dessus de la bulle */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #858585 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 0.95;
}