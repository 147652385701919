.file-tree-container {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  width: 100%;
  max-height: calc(1.2em * 30);
  overflow-y: auto;
  padding-bottom: 20px;
}


.file-tree-table {
  width: 100%;
  border-collapse: collapse;
}

.file-tree-table th,
.file-tree-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.file-tree-table th {
  background-color: #f2f2f2;
}

.file-tree-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.file-tree-table tr:hover {
  background-color: #f0f0f0;
}

.action-icons {
  display: flex;
  gap: 5px;
  /* Espacement entre les icônes */
}

.action-icons>span {
  color: rgb(175, 175, 175);
  opacity: 0.7;
  /* Opacité par défaut des icônes */
  transition: opacity 0.3s ease;
  /* Transition de l'opacité */
}


.action-icons>span:hover {
  color: rgb(80, 80, 80);
  opacity: 1;
  /* Opacité maximale lors du survol */
}

/* Nouvelle règle CSS pour l'état de survol de l'icône à l'intérieur de la div .tooltip */
.tooltip .action-icons>span:hover {
  opacity: 1;
}

.filters {
  margin-bottom: 10px;
  /* Ajoute de l'espace en dessous de la zone de filtrage */
  margin-top: 10px;
  /* Ajoute de l'espace en dessous de la zone de filtrage */
}

.filters input {
  padding: 8px;
  /* Ajoute du rembourrage à l'intérieur des champs de saisie */
  margin-right: 10px;
  /* Ajoute un peu d'espace entre les champs de saisie */
  border-radius: 4px;
  /* Ajoute des coins arrondis aux champs de saisie */
  border: 1px solid #ccc;
  /* Ajoute une bordure aux champs de saisie */
}

.newdir .button {
  background-color: gray;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 10px 20px;
  color: gray;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 50px;
}

.newdir .button:hover {
  background-color: gray;
  color: white;
  width: 50px;
}

.newdir input {
  padding: 8px;
  /* Ajoute du rembourrage à l'intérieur des champs de saisie */
  margin-right: 10px;
  /* Ajoute un peu d'espace entre les champs de saisie */
  border-radius: 4px;
  /* Ajoute des coins arrondis aux champs de saisie */
  border: 1px solid #ccc;
  /* Ajoute une bordure aux champs de saisie */
  width: 300px;
}