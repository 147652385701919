.Navbar {
  background-color: #0d160f;
  /* Couleur de fond sombre */
  display: flex;
  justify-content: space-between;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

}

.Navbar-logo {
  display: flex;
  align-items: center;
}

.Navbar-logo-tcp {
  height: 100%;
}

.Navbar-tcp {
  color: #fff;
  /* Couleur du texte en blanc */
  font-size: 24px;
  text-decoration: none;
}

.Navbar li {
  margin: 0 15px;
}

.Navbar ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.Navbar a {
  text-decoration: none;
  color: #fff;
  /* Couleur du texte en blanc */
  font-weight: bold;
}

li a:hover:not(.active) {
  color: rgb(247, 197, 35);
  /* Couleur du texte en blanc */
}

li .active {
  color: rgb(247, 197, 35);
  /* Couleur du texte en blanc */

}

button {
  background-color: lightgrey;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 5px;
}

button.clicked {
  background-color: blue;
  color: white;
}


@media screen and (max-width: 768px) {
  .Navbar {
    flex-direction: column;
  }

  .Navbar ul {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }


  .Navbar-logo {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .Navbar li {
    margin: 10px 0;
  }
}