.liste-noms {
  list-style-type: none;
  padding: 0;
}

.liste-noms li {
  margin-bottom: 20px;
  cursor: pointer;
}


.icon {
  /* Calcul dynamique de la couleur en fonction de l'index */
  color: hsl(calc((var(--color-index) * (360 / var(--total-items))) / 360), 70%, 50%);
}

.nom-groupe {
  margin-left: 10px;
  /* Ajoute de l'espace entre l'icône et le nom du groupe */
}

.nom-selectionne {
  font-weight: bold;

  font-size: large;
}