body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}

.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 1200px;
    min-width: 300px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    font-size: 16px;
    /* Taille du bouton de fermeture */
    cursor: pointer;
    /* Curseur indiquant que le bouton est cliquable */
    width: 30px;
    /* Largeur fixe du bouton */
    height: 30px;
    /* Hauteur fixe du bouton */
    text-align: center;
    /* Centrage horizontal du contenu */
    line-height: 1;
    /* Ajustement de la hauteur de la ligne pour centrer verticalement */
}



/* Style supplémentaire pour améliorer l'aspect de la croix */
.close-modal::after {
    font-size: 24px;
    /* Taille de la croix */
    font-weight: bold;
    /* Texte en gras pour plus de visibilité */
    color: #888;
    /* Couleur de la croix */
}

/* Pour que le curseur change au survol du bouton */
.close-modal:hover {
    color: #555;
    /* Changement de couleur au survol */
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

/* Ajout de styles pour limiter la hauteur du contenu à 10 lignes et activer le défilement si nécessaire */
.content-wrapper {
    max-height: calc(1.2em * 20);
    /* Limite le contenu à 10 lignes */
    overflow-y: auto;
    /* Activer le défilement vertical si le contenu dépasse la hauteur */
    /*max-width: calc(1.2em * 50); /* Limite le contenu à 10 lignes */
    /*overflow-x: auto;  Activer le défilement vertical si le contenu dépasse la hauteur */
}

.modal-header {
    cursor: move;
    /* Permet de déplacer la modal */
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #000;
    cursor: se-resize;
    /* Permet de redimensionner la modal */
    bottom: 0;
    right: 0;
}