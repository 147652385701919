/* Login.css */


.login-page {
  background-image: url('https://s3.fr-par.scw.cloud/static.tcp/TCP_Fond.png');
  /* Ajoutez le chemin vers votre image de fond */
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  background-color: rgba(0, 0, 0, 0.6);
  /* Couleur de fond semi-transparente */
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 400px;
  /* Vous pouvez ajuster cette valeur en fonction de vos besoins pour les écrans larges */
  width: 80%;
  /* Ajuste la largeur en pourcentage */
}

/* Media query pour les écrans de petite taille (téléphones) */
@media (max-width: 767px) {
  .login-container {
    max-width: 100%;
    /* Pour s'adapter à la largeur de l'écran sur les téléphones */
    border-radius: 0;
    /* Supprimer la bordure arrondie pour les petits écrans si nécessaire */
  }
}

.logo img {
  width: 400px;
  /* Ajustez la taille de votre logo */
  /*height: 100px; /* Ajustez la taille de votre logo */
  margin-bottom: 20px;
}

.login-form h2 {
  color: #fff;
  /* Couleur du texte en blanc */
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 10px;
  text-align: left;
}

.form-group label {
  color: #fff;
  /* Couleur du texte en blanc */
  font-size: 14px;
}

.form-group input {
  width: 95%;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #555;
  /* Couleur de fond du champ d'entrée */
  color: #fff;
  /* Couleur du texte en blanc */
  margin-top: 5px;
}




button {
  width: 100%;
  padding: 10px;
  background-color: #000000;
  /* Couleur de fond du bouton */
  color: #fff;
  /* Couleur du texte en blanc */
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
  /* Couleur de fond du bouton au survol */
}

/* Vous pouvez ajouter d'autres styles CSS selon vos besoins */